<template lang="pug">
    .dashboard-page
        DashboardWrapper(:title="activeDashboardComponent.title || null", :button="activeDashboardComponent.mastheadButton" :component="activeDashboardComponent.mastheadComponent")
          component(v-if="activeDashboardComponent && activeDashboardComponent.title", :is="activeDashboardComponent", :key="page || 0")
</template>

<script>
import { get, call } from "vuex-pathify";
import DashboardWrapper from "@/components/Dashboard/DashboardWrapper";
export default {
  name: "DashboardPage",
  components: { DashboardWrapper },
  watch: {
    $route() {
      this.init();
    }
  },
  data() {
    return {
      page: null
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...get({
      activeDashboardComponent: "Nav/activeDashboardComponent"
    })
  },
  methods: {
    ...call({
      setActiveDashboardComponent: "Nav/setActiveDashboardComponent"
    }),
    init() {
      this.page = this.$route.params.page;
      this.setActiveDashboardComponent({ page: this.page });
    }
  }
};
</script>
