<template lang="pug">
    .dashboard-nav
        v-navigation-drawer(app permanent v-model="showNav", :mini-variant.sync="miniNav")
            template(template v-slot:prepend)
                .ma-3
                    v-btn(icon color="white" @click="miniNav = !miniNav").mx-auto.accent
                        v-icon {{miniNav ? "mdi-menu" : "mdi-close"}}
            .mt-12
                v-list-item(key="brand" link :to="home.link").mb-6
                  .brand.d-flex.align-center.justify-center(:class="{'mx-auto': miniNav}")
                    v-img(:src="miniNav ? miniLogo : expandedLogo" min-width="25px" :max-width="miniNav ? '25px' : '200px'" contain :aspect-ratio="miniNav ? 1 : null")
                v-list-item(v-for="item in items", :key="item.text" link :to="item.link" :class="item.class")
                    v-list-item-icon
                      v-icon().mr-2 {{item.icon}}
                    v-list-item-content
                      span {{item.text}}


</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "MenuZWAdmin",
  data() {
    return {
      expandedLogo: require("@/assets/logos/logo_unitfinancials_500.png"),
      miniLogo: require("@/assets/logos/logo_unitfinancials_mini.png"),
      items: [
        {
          text: "Systems",
          icon: "mdi-sitemap",
          link: "/zwadmin/systems",
          class: ""
        },
        { text: "Logout", icon: "mdi-logout", link: "/logout", class: "logout" }
      ]
    };
  },
  computed: {
    ...sync({
      showNav: "Nav/showNav",
      miniNav: "Nav/miniNav"
    }),
    home() {
      return this.items.find(itm => itm.link === "/zwadmin/systems");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  ::v-deep
  .v-navigation-drawer__border {
  background-color: $accent;
  opacity: 0.3;
}
.v-list-item--active {
  &:before {
    color: white;
  }

  .v-list-item__content {
    font-weight: 500;
    color: $primary;
    z-index: 1;
  }

  .v-icon {
    color: $accent;
  }
}
</style>
