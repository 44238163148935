<template lang="pug">
    .dashboard-wrapper
        MenuZWAdmin(v-if="isZWAdmin")
        MenuUser(v-else)
        .pa-5
          v-row(dense justify="space-between" align="center").mb-6
              v-col
                h1 {{title}}
              v-col.text-right(v-if="button || component")
                v-btn(v-if="button", color="accent" depressed :to="button.to" @click="handleClick") {{button.text}}
                component(v-if="component", :is="component")
          slot
</template>

<script>
import { call, get } from "vuex-pathify";
import MenuUser from "@/components/Global/MenuUser.vue";
import MenuZWAdmin from "@/components/Global/MenuZWAdmin.vue";
export default {
  name: "DashboardWrapper",
  components: { MenuUser, MenuZWAdmin },
  props: {
    title: { type: String, default: "Dashboard" },
    button: { type: Object, default: null },
    component: { type: Object, default: null }
  },
  created() {
    this.init();
  },
  computed: {
    ...get({ userData: "User/userData" }),
    isZWAdmin() {
      return (
        this.userData.type === "ZEEWISE_ADMIN" &&
        this.userData.email === "zwadmin@zeewise.com"
      );
    }
  },
  methods: {
    ...call({
      showConnectNewDataSourceModal: "Nav/showConnectNewDataSourceModal"
    }),
    handleClick() {
      if (this.button.to) {
        this.$router.push(this.button.to);
      } else if (this.button.modal) {
        const modalToCall = this.button.modal;
        if (modalToCall === "CONNECT_NEW_DATA_SOURCE") {
          this.showConnectNewDataSourceModal(true);
        }
      }
    },
    init() {}
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
h1 {
  color: $primary;
}
</style>
